
import SelectCity from './pages/SelectCity';
import Login from './pages/Signin'
import ForgotPassword from './pages/ForgotPassword';
import NotFound from './pages/NotFound';
import { Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route index element={<SelectCity />} />
        <Route path="login" element={<Login />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
}

export default App;
