import axios from "axios";

export default function customAxios(subdomain=null) {

    const getBaseUrl = () => {
        if (subdomain) {
            return `${process.env.REACT_APP_API_BASE_PROTOCOL}${subdomain}/${process.env.REACT_APP_API_BASE_PATH}`;
        } else {
            return `${process.env.REACT_APP_API_BASE_PROTOCOL}${process.env.REACT_APP_API_BASE_DOMAIN}/${process.env.REACT_APP_API_BASE_PATH}`;
        }
    }

    const axiosInstance = axios.create({
        baseURL: getBaseUrl()
    });

    return axiosInstance;
};