import React, { useCallback, useEffect, useState } from 'react'

import Copyright from '../../components/copyright';
import Logo from '../../components/logo';
import customAxios from '../../services/api';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import Button from '../../components/button/button';

export default function SelectCity() {

    const navigate = useNavigate();
    const axios = customAxios();

    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCity, setSelectedCity] = useState('');

    const [cookies, setCookie] = useCookies(['subdomain']);

    useEffect(() => {
        document.title = "Selecione uma cidade"
        
    }, [])

    const handleChange = e => {
        if (e.target.value === '-1') {
            setCookie('subdomain', '-1', { domain: process.env.REACT_APP_API_BASE_DOMAIN });
            setSelectedCity('-1')
        } else {
            cities.map(city => {
                if (e.target.value === city.domain) {
                    setCookie('subdomain', city, { domain: process.env.REACT_APP_API_BASE_DOMAIN });
                    setSelectedCity(city.domain)
                }
                return true
            })
        }
    }

    // eslint-disable-next-line
    const nextPage = () => navigate("/login");

    const getCities = useCallback(async () => {
        try {
            setLoading(true)
            const { data } = await axios.get('/clients/cities');
            setCities(data.data)
            if (cookies.subdomain) {
                data.data.map(city => {
                    if (city.domain === cookies.subdomain.domain) {
                        setSelectedCity(cookies.subdomain.domain)
                        nextPage();
                    }
                    return true;
                })
            }
            if (data.data.length === 1) {
                setCookie('subdomain', data.data[0], { domain: process.env.REACT_APP_API_BASE_DOMAIN });
                setSelectedCity(data.data[0].domain)
                nextPage();
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error);
        }
    },[axios, cookies.subdomain, nextPage, setCookie])

    useEffect(() => getCities(), [getCities])

    

    return (
        <div className='flex items-center justify-center w-screen h-screen bg-gray-200 p-9'>
            <div className='flex flex-col gap-10'>
                <div className='max-w-xs'>
                    <Logo />
                </div>
                <div className='flex flex-col gap-4'>
                    <div className=''>
                        <select value={selectedCity} onChange={handleChange} className='w-full px-6 text-lg border rounded-lg bg-slate-300 border-slate-400 h-14' placeholder='Selecione uma cidade...'>
                            {
                                loading ?
                                    <option key="0" value="-1">aguarde...</option> :
                                    <option key="0" value="-1">Selecione uma cidade...</option>
                            }
                            {
                                cities.map(city => <option key={city.domain} value={city.domain}>{city.city}</option>)
                            }
                        </select>
                    </div>
                </div>
                <div className='flex flex-col gap-4'>
                    <Button 
                    loading={loading} 
                    onClick={nextPage}
                    disabled={!selectedCity || selectedCity === '-1'} 
                    label="Avançar" />
                </div>
                <Copyright />
            </div>
        </div>
    )
}
