import React, { useCallback, useEffect } from 'react'

export default function Button({
    label,
    secondary = false,
    flat = false,
    loading = false,
    onClick,
    disabled = false,
    ...props
}) {

    const styleDefault = "text-white bg-slate-500"
    const styleSecondary = "text-slate-500 border-slate-400 border"
    const styleFlat = "text-slate-500"
    const styleLoading = "text-slate-400 border-slate-400 border bg-slate-300"

    const [text, setText] = React.useState(label)
    const [styleButton, setStyleButton] = React.useState(styleDefault)
    
    const selectStyleButton = useCallback(() => {
        if (disabled) {
            setText(label)
            return styleLoading
        }
        if (!flat && !secondary) return styleDefault
        if (secondary) return styleSecondary
        if (flat) return styleFlat
    },[label, disabled,flat, secondary])

    useEffect(() => setStyleButton(selectStyleButton), [disabled,selectStyleButton])

    const checkStateButtom = useCallback(() => {
        if (loading) {
            setText('processando...')
            setStyleButton(styleLoading)
        } else {
            setText(label)
            setStyleButton(selectStyleButton)
        }
      }, [loading, label,selectStyleButton])
    
    useEffect(() => checkStateButtom(), [checkStateButtom])

    const onClickHandler = () => {
        if (!loading && !disabled) { onClick && onClick() }
    }

    return (
        <button
            {...props}
            onClick={onClickHandler}
            className={`w-full text-lg rounded-lg hover:font-bold h-14 ${styleButton}`}
        >{text}</button>
    )
}
