import React from 'react'

export default function Copyright() {
    return (
        <div className='flex flex-col justify-center text-xs text-slate-400'>
            <div className='text-center'>Software by Blit Softwares e Tecnologia Digital LTDA</div>
            <div className='text-center'>CNPJ: 26.361.810/0001-67</div>
        </div>
    )
}
