import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";

export default function CurrentCity() {
    const navigate = useNavigate();
    const [cookies, removeCookie] = useCookies(['subdomain']);

    useEffect(() => {
        if (
            !cookies.subdomain ||
            !cookies.subdomain.domain ||
            cookies.subdomain.domain === '-1'
        ) {
            return navigate('/')
        }
    }, [cookies,navigate])

    const clearCookies = () => {
        removeCookie('subdomain')
    }

    return (
        <div className='flex justify-center gap-2 text-lg font-bold uppercase border text-slate-700'>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                </svg>
            </div>
            <div>{
                cookies && cookies.subdomain &&
                cookies.subdomain !== '-1' &&
                cookies.subdomain.city}</div>
            <button onClick={clearCookies} className='px-3 cursor-pointer text-slate-500 hover:text-green-500'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
            </button>
        </div>
    )
}
