import React, { useCallback, useEffect, useState } from 'react'

import Copyright from '../../components/copyright';
import Logo from '../../components/logo';
import customAxios from '../../services/api';
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import Button from '../../components/button/button';
import CurrentCity from '../../components/currentCity/currentCity';

export default function Signin() {

    const [cookies, setCookie] = useCookies(['access_token', 'subdomain', 'user']);
    const navigate = useNavigate();
    const axios = customAxios(cookies.subdomain && cookies.subdomain.domain);

    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('master');
    const [password, setPassword] = useState('w2E#r4T%')
    const [message, setMessage] = useState('');


    useEffect(() => {
        document.title = "Login"
    }, [])

    const checkAuthentication = useCallback(() => {
        setLoading(true)
        if (
            !cookies.subdomain ||
            !cookies.subdomain.domain ||
            cookies.subdomain.domain === '-1' ||
            cookies.subdomain === 'undefined'
        ) return false

        if (!cookies.access_token || cookies.access_token === 'undefined') return false;
        if (!cookies.user || cookies.user === 'undefined') return false;
        return true;
    },[cookies.subdomain, cookies.access_token, cookies.user])

    useEffect(() => {
        if (checkAuthentication()) {
            window.location.replace(process.env.REACT_APP_DASHBOARD_URL)
        } else { setLoading(false) }
    }, [checkAuthentication])

    const validateFields = () => {
        if (!username) return false
        if (!password) return false
        return true
    }

    const handleChange = (e, action) => {
        validateFields()
        action(e.target.value)
    }

    const handleSubmit = async (e) => {
        try {
            setMessage('')
            setLoading(true)
            const { data } = await axios.post('/auth/login', { username, password });
            setCookie('access_token', data.data.access_token, { domain: process.env.REACT_APP_API_BASE_DOMAIN });
            setCookie('user', data.data.user, { domain: process.env.REACT_APP_API_BASE_DOMAIN });
            window.location.replace(process.env.REACT_APP_DASHBOARD_URL)
        } catch (error) {
            setLoading(false)
            setMessage(error.response.data.errors)
        }
    }

    return (
        <div className='flex items-center justify-center w-screen h-screen bg-gray-200 p-9'>
            <div className='flex flex-col max-w-xs gap-6'>
                <div >
                    <Logo />
                </div>
                <CurrentCity />
                <div className='flex flex-col gap-4'>
                    <div className=''>
                        <div className='text-slate-500'>Nome de usuário:</div>
                        <input value={username} onChange={e => handleChange(e, setUsername)} className='w-full px-6 text-lg border rounded-lg bg-slate-300 border-slate-400 h-14' />
                    </div>
                    <div className=''>
                        <div className='text-slate-500'>Senha:</div>
                        <input value={password} onChange={e => handleChange(e, setPassword)} type={"password"} className='w-full px-6 text-lg border rounded-lg bg-slate-300 border-slate-400 h-14' />
                    </div>
                </div>
                {
                    message &&
                    <div className='p-4 text-sm text-orange-700 bg-orange-200 border border-orange-400 rounded-lg'>
                        {message}
                    </div>
                }
                <div className='flex flex-col gap-4'>
                    <Button loading={loading} disabled={validateFields() === false} onClick={handleSubmit} label="Entrar" />
                    <Button onClick={() => navigate('/forgot-password')} label="Esqueci a senha?" flat />
                </div>
                <Copyright />
            </div>
        </div>
    )
}
