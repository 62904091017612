import React, { useEffect } from 'react'

import Copyright from '../../components/copyright';
import CurrentCity from '../../components/currentCity/currentCity';
import Logo from '../../components/logo';

export default function ForgotPassword() {

    useEffect(() => {
        document.title = "Esqueci a senha"
    })

    return (
        <div className='flex items-center justify-center w-screen h-screen bg-gray-200 p-9'>
            <div className='flex flex-col gap-10'>
                <div className='max-w-xs'>
                    <Logo />
                </div>
                <CurrentCity />
                <div className='flex flex-col gap-4'>
                    <div className=''>
                        <input className='w-full px-6 text-lg border rounded-lg bg-slate-300 border-slate-400 h-14' value={"lçadoas"} />
                    </div>
                </div>
                <div className='flex flex-col gap-4'>
                    <button className='w-full text-lg text-white rounded-lg bg-slate-500 h-14'>Recuperar senha</button>
                    <button className='w-full text-lg border rounded-lg text-slate-500 h-14 border-slate-400'>Voltar</button>
                </div>
                <Copyright />
            </div>
        </div>
    )
}
